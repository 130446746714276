<template>
	<div class="container">
		<div class="search" style="display: flex">
			<el-form ref="form" style="flex: 1" :inline="true" :model="search" class="demo-form-inline">
				<el-form-item>
					<el-input v-model.trim="search.kname" @change="Search" placeholder="名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model.trim="search.model" @change="Search" placeholder="产品型号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model.trim="search.category_code" @change="Search" placeholder="所属品类"></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="search.client_brand_code" clearable @change="Search" placeholder="所属品牌">
						<el-option v-for="item in brandList" :key="item.code" :label="item.name" :value="item.code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input v-model.trim="search.schema" @change="Search" placeholder="品牌应用"></el-input>
				</el-form-item>
			</el-form>
			<div>
				<router-link target="_blank" style="margin-left: 10px" :to="'/iot/promodel/modelconfig/' +0 +'&' +search.client_brand_code +'&model'">
					<el-button type="primary" size="small">新建</el-button>
				</router-link>
			</div>
		</div>
		<!-- 列表 -->
		<el-table border v-bind:data="list">
			<!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
			<el-table-column label="名称">
				<template v-slot="scope">
					<span>{{ scope.row.name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="产品型号">
				<template v-slot="scope">
					<span>{{ scope.row.model }}</span>
				</template>
			</el-table-column>
			<el-table-column label="所属品牌" width="160px">
				<template v-slot="scope">
					<span>{{ brandNameCode[scope.row.client_brand_code] }}</span>
				</template>
			</el-table-column>
			<el-table-column label="schema" width="160px">
				<template v-slot="scope">
					<span>{{ scope.row.schema_name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="状态" width="160px">
				<template v-slot="scope">
					<span>{{scope.row.status == 0? "测试": scope.row.status == 1? "上架": scope.row.status == 2? "下架": "未知"}}</span>
				</template>
			</el-table-column>
			<el-table-column label="功耗类型" width="160px">
				<template v-slot="scope">
					<span>{{scope.row.model_type == 1? "普通设备": scope.row.model_type == 2? "低功耗设备": "未知"}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template v-slot="scope">
					<router-link target="_blank"  style="margin-left: 5px; margin-right: 5px" :to="'/iot/model/attributes?model=' +scope.row.code">
						<el-button type="info" size="small" plain>分配属性</el-button>
					</router-link>
					
					<router-link target="_blank"  style="margin-left: 10px; margin-right: 10px" :to="'/iot/promodel/modelconfig/' +scope.row.code +'&' + search.client_product_code +'&model'">
						<el-button type="primary" size="small">修改</el-button>
					</router-link>
					<el-button type="info" size="small" @click="GetSecret(scope.row.code)">密钥</el-button>
					<el-button type="danger" size="small" @click="deleteModel(scope.row.code)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handleCurrentChange" v-model:currentPage="search.page" :page-size="search.limit" layout="total, prev, pager, next" :total="total">
		</el-pagination>

		<!-- 密钥 -->
		<el-dialog title="产品型号密钥" v-model="secretFormVisible" width="600px">
			<el-form :model="secretform" ref="secretform">
				<el-form-item label="密钥" label-width="100px">
					<el-input v-model="secretform.modelsecret" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="secretFormVisible = false">取 消</el-button>
				</span>
			</template>
		</el-dialog>

		<!-- 新增修改 -->
		<el-dialog :title="title + '产品型号'" v-model="dialogFormVisible" width="600px">
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item prop="client_product_code" label="产品编号" label-width="100px">
					<el-select v-model="form.client_product_code">
						<el-option v-for="item in prolist" :key="item.code" :label="item.name" :value="item.code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="model" label="产品型号" label-width="100px">
					<el-input v-model="form.model" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item prop="name" label="产品型号名称" label-width="100px">
					<el-input v-model="form.name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item prop="secret" label="产品型号秘钥" label-width="100px">
					<el-input v-model="form.secret" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogFormVisible = false">取 消</el-button>
					<el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
					<el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script type="text/ecmascript-6">
	import api from "../../../axios/secret";
	export default {
		name: "",
		mixins: [],
		components: {},
		props: {},
		data() {
			return {
				search: {
					page: 1,
					limit: 10,
					kname: "",
					model: "",
					client_product_code: "",
					schema: "",
					category_code: "",
					client_brand_code: "",
				},
				list: [],
				total: 0,
				dialogFormVisible: false,
				edit: false,
				title: "",
				form: {
					code: "",
					client_product_code: "",
					model: "",
					name: "",
					secret: "",
				},
				rules: {
					client_product_code: [{
						required: true,
						message: "请输入产品编号",
						trigger: "blur"
					}, ],
					model: [{
						required: true,
						message: "请输入产品型号",
						trigger: "blur"
					}],
					name: [{
						required: true,
						message: "请输入产品型号名称",
						trigger: "blur"
					}, ],
					secret: [{
						required: true,
						message: "请输入产品型号秘钥",
						trigger: "blur"
					}, ],
				},
				prolist: [],
				brandList: [],
				proNameCode: {},
				brandNameCode: {},
				secretform: {
					modelsecret: "",
				},
				secretFormVisible: false,
			};
		},
		computed: {},
		watch: {},
		methods: {
			GetSecret(item) {
				this.secretFormVisible = true;
				api
					.clientmodelSecret({
						code: item,
					})
					.then((res) => {
						if (res.data.code == 200) {
							this.secretform.modelsecret = res.data.data.secret;
							return;
						}
						this.$message.error("获取失败" + res.data.msg);
					});
			},
			handleCurrentChange(val) {
				this.search.page = val;
				sessionStorage.setItem("currentPage", val);
				this.Search();
			},
			// GetProList(){
			//   this.api.ProductList({
			//     page: 1,
			//     limit: 100,
			//   }).then(res => {
			//     if (res.data.code == 200) {
			//       this.prolist = res.data.data.data
			//       res.data.data.data.forEach(item => {
			//         this.proNameCode[item.code] = item.name
			//       })
			//       return
			//     }
			//     this.$message.error('获取失败' + res.data.msg)
			//   })
			// },
			GetBrandList() {
				this.api
					.BrandList({
						page: 1,
						limit: 100,
					})
					.then((res) => {
						if (res.data.code == 200) {
							this.brandList = res.data.data.data;
							res.data.data.data.forEach((item) => {
								this.brandNameCode[item.code] = item.name;
							});
							return;
						}
						this.$message.error("获取失败" + res.data.msg);
					});
			},
			Search() {
				this.api.ProductModelList(this.search).then((res) => {
					if (res.data.code == 200) {
						this.list = res.data.data.data;
						this.total = res.data.data.page_info.total;
						return;
					}
					this.$message.error("获取失败" + res.data.msg);
				});
			},
			addModel() {
				this.$nextTick(() => {
					this.$refs.form.resetFields();
				});
				this.form = {};
				this.form.client_product_code = this.$route.params.code;
				this.edit = false;
				this.title = "新增";
				this.dialogFormVisible = true;
			},
			editModel(item) {
				this.form = {};
				this.$nextTick(() => {
					this.$refs.form.resetFields();
				});
				this.edit = true;
				this.title = "编辑";
				this.dialogFormVisible = true;
				this.api.ProductModelDetail({
					code: item.code
				}).then((res) => {
					if (res.data.code == 200) {
						this.form = res.data.data;
						return;
					}
					this.$message.error("获取详情失败" + res.data.msg);
				});
			},
			deleteModel(item) {
				this.$confirm("是否删除该产品型号?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						// 删除请求
						this.api.ProductModelDelete({
							code: item
						}).then((res) => {
							if (res.data.code == 200) {
								this.$message({
									type: "success",
									message: "删除成功!",
								});
								this.Search();
								return;
							}
							this.$message.error("删除失败" + res.data.msg);
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "取消删除",
						});
					});
			},
			deleteDistri(item) {
				this.$confirm("是否取消该型号分配?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.api
						.Productconfig({
							client_product_code: this.search.client_product_code,
							code: item,
							status: 2,
						})
						.then((res) => {
							if (res.data.code == 200) {
								this.$message({
									type: "success",
									message: "取消分配成功!",
								});
								this.Search();
								return;
							}
							this.$message.error("取消分配失败" + res.data.msg);
						});
				});
			},
			Validate: function(call) {
				this.$refs["form"].validate((res) => {
					if (res && call) {
						call();
					}
				});
			},
			update() {
				this.api.ProductModelUpdate(this.form).then((res) => {
					if (res.data.code == 200) {
						this.$message.success(this.title + "成功");
						this.dialogFormVisible = false;
						this.Search();
						return;
					}
					this.$message.error(this.title + "失败" + res.data.msg);
				});
			},
			addconfirm() {
				this.update();
			},
			editconfirm() {
				this.update();
			},
			getSchoolWebModuleMessageListFunc() {
				setTimeout(() => {
					if (
						sessionStorage.getItem("currentPage") &&
						sessionStorage.getItem("currentPage") !== 1
					) {
						this.search.page = Number(sessionStorage.getItem("currentPage"));
					}
					this.Search();
				}, 0);
			},
		},
		filters: {},
		mounted() {
			this.getSchoolWebModuleMessageListFunc();
		},
		created() {
			// this.GetProList()
			this.GetBrandList();
		},
	};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>