import { fetch } from './fetch'
import api from './url'

export default {
    appjpush: function (params) {
      return fetch({
        url: api.appjpush,
        method: 'get',
        params: params
      })
    },
    appapnspush: function (params) {
      return fetch({
        url: api.appapnspush,
        method: 'get',
        params: params
      })
    },
    appgetuipush: function (params) {
      return fetch({
        url: api.appgetuipush,
        method: 'get',
        params: params
      })
    },
    appumpush: function (params) {
      return fetch({
        url: api.appumpush,
        method: 'get',
        params: params
      })
    },
    appfcmpush: function (params) {
      return fetch({
        url: api.appfcmpush,
        method: 'get',
        params: params
      })
    },
    clientapp: function (params) {
      return fetch({
        url: api.clientapp,
        method: 'get',
        params: params
      })
    },
    clientdevice: function (params) {
      return fetch({
        url: api.clientdevice,
        method: 'get',
        params: params
      })
    },
    client: function (params) {
      return fetch({
        url: api.client,
        method: 'get',
        params: params
      })
    },
    clientmodelSecret: function (params) {
      return fetch({
        url: api.clientmodelSecret,
        method: 'get',
        params: params
      })
    },
}